<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
      <!-- 교육대상자 추가 -->
      <c-card title="교육대상자 추가" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-3">
            <c-plant type="search" name="plantCd" :editable="editable && !disabled" v-model="searchParam.plantCd"/>
          </div>
          <div class="col-5">
            <!-- 부서 -->
            <c-dept
              :editable="editable && !disabled"
              :isFirstValue="false"
              :plantCd="searchParam.plantCd"
              label="부서"
              name="deptCd"
              v-model="searchParam.deptCd">
            </c-dept>
          </div>
          <div class="col-4">
            <!-- 사용자 -->
            <c-text
              :editable="editable && !disabled"
              label="사용자"
              name="userName"
              v-model="searchParam.userName"
              @input="getTargetUserList">
            </c-text>
          </div>
          <div class="col-12" style="margin-top: -20px">
            <div class="row">
              <div class="col-11">
                <!-- 사용자 목록 -->
                <c-table
                  ref="gridUser"
                  title="사용자 목록"
                  tableId="gridUser"
                  :columns="gridUser.columns"
                  :gridHeight="gridUser.height"
                  :data="gridUser.data"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :editable="editable && !disabled"
                  :expandAll="true"
                  :isExcelDown="false"
                  selection="multiple"
                  rowKey="userId"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                                  <c-btn
                        label="검색"
                        icon="search" @btnClicked="getTargetUserList" v-if="editable && !disabled"/>
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-1" style="line-height: 35px; text-align:center;padding-top:100px !important">
                <c-btn v-if="editable && !disabled" label="" icon="arrow_forward_ios" color="blue-7" @btnClicked="selectAdd" />
              </div>
            </div>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
      <!-- 교육대상자 목록 -->
      <c-table
        ref="table"
        title="교육대상자 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :checkClickFlag="false"
        :columnSetting="false"
        :usePaging="false"
        :merge="grid.merge"
        :isExcelDown="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 교육과정 기본대상자 추가 -->
            <c-btn v-if="editable && !disabled" label="교육과정 기본대상자 추가" :showLoading="false"  icon="add" @btnClicked="addUserCourse" />
            <!-- 교육대상자 업로드 -->
            <c-btn v-if="editable && !disabled" label="교육대상자 업로드" :editable="editable" icon="assignment" @btnClicked="openExcelUploader"/>
            <!-- <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem('DEPT')" /> -->
            <!-- <c-btn
              v-if="editable && !disabled && grid.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave1"
              :param="grid.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveEducation1"
              @btnCallback="saveCallback1" 
            /> -->
            <c-btn v-if="editable && !disabled && grid.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 협력업체 직원 -->
      <c-table
        ref="table4"
        title="협력업체 직원"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :checkClickFlag="false"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem4" />
              <c-btn v-if="editable && !disabled && grid2.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem4" />
              <c-btn
              v-if="editable && !disabled && grid2.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave2"
              :param="grid2.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveEducation2"
              @btnCallback="saveCallback2" 
            />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-target-user',
  props: {
    educationInfo: {
      type: Object,
      default: () => ({
        plantCd: '',
        eduEducationId: '',
        eduCourseId: '',
        documentStatusCd: '',
        eduAttendeeList: [],
        educationTime: '',
      }),
    },
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:25%',
            // 부서
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:25%',
            // 직책
            label: '직책',
            align: 'center',
            sortable: false,
          },
          {
            name: 'seniorName',
            field: 'seniorName',
            style: 'width:25%',
            // 선임명
            label: '선임명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            // 대상자
            label: '대상자',
            style: 'width:25%',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '613px'
      },
      grid2: {
        columns: [
          {
            required: true,
            name: 'companyName',
            field: 'companyName',
            // 소속회사
            label: '소속회사',
            align: 'center',
            style: 'width:25%',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:25%',
            // 직책
            label: '직책',
            type: 'text',
            align: 'center',
            sortable: false,
          },
          {
            required: true,
            name: 'attendeesName',
            field: 'attendeesName',
            // 대상자
            label: '대상자',
            type: 'text',
            style: 'width:25%',
            align: 'center',
            sortable: false
          },
          {
            name: 'residentNo',
            field: 'residentNo',
            // 생년월일
            label: '생년월일',
            type: 'date',
            style: 'width:25%',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '260px'
      },
      gridUser: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: "jobName",
            field: "jobName",
            // 직무
            label: "직무",
            align: "center",
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            // 사용자
            label: '사용자',
            align: 'center',
            sortable: false,
          },
          {
            name: "enterDate",
            field: "enterDate",
            // 입사일
            label: "입사일",
            align: "center",
            sortable: true,
          },
        ],
        height: '483px',
        data: [],
      },
      editable: true,
      getUrl: '',
      targetClassCd: '',
      updateMode: false,
      items: [],

      saveUrl: 'transactionConfig.sop.edu.plan.insert.url',
      saveType: 'POST',
      isSave1: false,
      isSave2: false,
      updateUrl: '',
      insertUrl: '',
      deleteUrl: '',
      targetUserUrl: '',
      courseUserUrl: '',
      searchParam: {
        plantCd: null,
        userName: '',
        deptCd: '',
      },
    };
  },
  computed: {
    disabled() {
      return Boolean(this.educationInfo.documentStatusCd) && this.educationInfo.documentStatusCd !== 'ESC000001' && !this.param.planUpdateBtnData.flag
    },
  },
  watch: {
    'educationInfo'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.targetUserUrl = selectConfig.sop.edu.user.list.url;
      this.courseUserUrl = selectConfig.sop.edu.course.targetlist.url;
      this.getUrl = selectConfig.sop.edu.attendee.get.url;
      this.insertUrl = transactionConfig.sop.edu.plan.insert.targetUser.url;
      this.deleteUrl = transactionConfig.sop.edu.plan.delete.targetUser.url;
      
      this.searchParam.plantCd = this.educationInfo.plantCd;
      // code setting
      this.getDetail();
      // list setting
    },
    getTargetUserList() {
      this.$http.url = this.targetUserUrl;
      this.$http.type = 'GET';
      this.$http.isLoading = false;
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridUser.data = _result.data;
        this.$refs['gridUser'].selected = [];
      },);
    },
    getDetail() {
      if (this.educationInfo.eduEducationId) {
        this.grid.data = [];
        this.grid2.data = [];
        this.$_.forEach(this.educationInfo.eduAttendeeList, _item => {
          if (_item.attendeesTypeCd === 'EATC00004') {
            this.grid2.data.push(_item) // 부서
          } else {
            this.grid.data.push(_item) // 선임
          }
        })
      }
    },
    getAttendList() {
      this.$http.url = this.$format(selectConfig.sop.edu.result.get.url, this.educationInfo.eduEducationId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.educationInfo.eduAttendeeList = _result.data.eduAttendeeList
        this.grid.data = [];
        this.grid2.data = [];
        this.$_.forEach(this.educationInfo.eduAttendeeList, _item => {
          if (_item.attendeesTypeCd === 'EATC00004') {
            this.grid2.data.push(_item) // 부서
          } else {
            this.grid.data.push(_item) // 선임
          }
        })
      },);
    },
    addItem(data) {
      if (data === 'DEPT') this.targetClassCd = '1';
      else this.targetClassCd = '4';

      if (this.targetClassCd === '4') {
        this.grid2.data.splice(0, 0, {
          eduCourseId: this.educationInfo.eduCourseId,
          attendeesNo: uuidv4(),
          eduEducationId: this.educationInfo.eduEducationId,
          attendeesTypeCd: 'EATC00004',
          attendeesTypeName: '',
          attendeesPositionName: '',
          companyName: '',
          residentNo: '',
          attendanceTime: this.educationInfo.educationTime,
          regUserId: this.$store.getters.user.userId,
          chgUserId: this.$store.getters.user.userId,
          attendeesName: '',
          educationCycle: '',
          editFlag: 'C'
        })
      } else {
        this.popupOptions.title = "교육대상자 검색"; // 교육대상자 검색
        this.popupOptions.param = {
          type: 'multiple',
          targetClassCd: this.targetClassCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/edu/result/educationCompletePop.vue'}`)
        this.popupOptions.visible = true;
        this.popupOptions.width = '70%';
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closeTargetUserPopup;
      }
    },
    closeTargetUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            attendeesId: _item.userId,
          });
          // 부서 EATC00003
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              eduCourseId: this.educationInfo.eduCourseId,
              attendeesNo: uuidv4(),
              attendeesTypeCd: 'EATC00003',
              eduEducationId: this.educationInfo.eduEducationId,
              attendeesTypeName: '',
              attendeesPositionName: _item.jobName,
              attendanceTime: this.educationInfo.educationTime,
              attendeesDeptName: _item.deptName,
              seniorityName: _item.seniorName,
              attendeesId: _item.userId,
              attendeesName: _item.userName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
            // 직책 EATC00001
        })
        /* eslint-disable no-unused-vars */ 
        this.$http.url = transactionConfig.sop.edu.plan.insert.targetUser.url;
        this.$http.type = 'POST';
        this.$http.param = this.grid.data;
        this.$http.request((_result) => {
          this.saveCallback1();
        },);
      }
    },  
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, { attendeesNo: item.attendeesNo })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.param.planUpdateBtnData.research = uid();
              this.getAttendList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addItem4() {
      this.grid2.data.splice(0, 0, {
        eduCourseId: this.educationInfo.eduCourseId,
        attendeesNo: uuidv4(),
        eduEducationId: this.educationInfo.eduEducationId,
        attendeesTypeCd: 'EATC00004',
        attendeesPositionCd: '',
        attendeesPositionName: '',
        companyName: '',
        residentNo: '',
        attendanceTime: this.educationInfo.educationTime,
        attendeesName: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeItem4() {
      let selectData = this.$refs['table4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, { attendeesNo: item.attendeesNo })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.param.planUpdateBtnData.research = uid();
              this.getAttendList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveEducation1() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid.data, item => {
            item.regUserId = this.$store.getters.user.userId
            item.chgUserId = this.$store.getters.user.userId
          })
          
          this.isSave1 = !this.isSave1;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveEducation2() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid2.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback1() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.planUpdateBtnData.research = uid();
      this.getAttendList();
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.planUpdateBtnData.research = uid();
      this.getAttendList();
    },
    innerBtnClicked4(col, props) {
      this.grid2.data.splice(props.rowIndex, 0, {
        key: props.row.key, 
        eduCourseId: this.educationInfo.eduCourseId,
        attendeesNo: uuidv4(),
        attendeesTypeCd: 'EATC00004',
        eduEducationId: this.educationInfo.eduEducationId,
        attendeesPositionCd: '',
        attendeesPositionName: props.row.attendeesPositionName,
        attendeesName: '',
        companyName: props.row.companyName,
        residentNo: props.row.residentNo,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      });
    },
    customColor(data, type) {
      if (type === 'EATC00001') {
        return 'primary';
      } else if (type === 'EATC00002') {
        return 'red';
      } else if (type === 'EATC00003') {
        return 'orange';
      } else {
        return 'green';
      }
    },
    openExcelUploader() {
      this.popupOptions.title = '교육대상자 업로드'; // 교육대상자 업로드
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'./eduTargetUserExcelUpload.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            empNo: _item.empNo,
          });
          // 부서 EATC00003
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              eduCourseId: this.educationInfo.eduCourseId,
              attendeesNo: uuidv4(),
              attendeesTypeCd: 'EATC00003',
              eduEducationId: this.educationInfo.eduEducationId,
              attendeesTypeName: '',
              attendeesPositionName: '',
              attendanceTime: this.educationInfo.educationTime,
              attendeesDeptName: '',
              seniorityName: '',
              empNo: _item.empNo,
              attendeesId: '',
              attendeesName: _item.userName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
            // 직책 EATC00001
        })
        
        /* eslint-disable no-unused-vars */ 
        this.$http.url = transactionConfig.sop.edu.plan.insert.exceltargetUser.url;
        this.$http.type = 'POST';
        this.$http.param = this.grid.data;
        this.$http.request((_result) => {
          this.saveCallback1();
        },);
      }
    },
    selectAdd() {
      this.$refs['table'].selected = [];
      let selectData = this.$refs["gridUser"].selected;
      if (selectData && selectData.length > 0) {
        this.$_.forEach(selectData, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            attendeesId: _item.userId,
          });
          // 부서 EATC00003
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              eduCourseId: this.educationInfo.eduCourseId,
              attendeesNo: uuidv4(),
              attendeesTypeCd: 'EATC00003',
              eduEducationId: this.educationInfo.eduEducationId,
              attendeesTypeName: '',
              attendeesPositionName: _item.jobName,
              attendanceTime: this.educationInfo.educationTime,
              attendeesDeptName: _item.deptName,
              seniorityName: _item.seniorName,
              attendeesId: _item.userId,
              enterDate: _item.enterDate,
              attendeesName: _item.userName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
        /* eslint-disable no-unused-vars */ 
        this.$http.url = transactionConfig.sop.edu.plan.insert.targetUser.url;
        this.$http.type = 'POST';
        this.$http.param = this.grid.data;
        this.$http.request((_result) => {
          this.saveCallback1();
          this.$refs['gridUser'].selected = [];
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '대상자를 선택하세요.', // 대상자를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    addUserCourse() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        // '교육과정 [' + this.educationInfo.educationCourseName + '] 에 설정된 기본 교육대상자를 해당 교육 대상자로 모두 추가하시겠습니까?
        message: this.$language('교육과정 [' + this.educationInfo.educationCourseName + '] 에 설정된 기본 교육대상자를 해당 교육 대상자로 모두 추가하시겠습니까?', {s1: this.educationInfo.educationCourseName}),
        
        type: 'info', // success / info / warning / error 
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.courseUserUrl
          this.$http.type = 'GET';
          this.$http.isLoading = false;
          this.$http.param = {eduCourseId: this.educationInfo.eduCourseId};
          this.$http.request((_result) => {
            let _plantData = _result.data.filter( x => {
              return x.plantCd == this.educationInfo.plantCd
            });
            this.$_.forEach(_plantData, _item => {
              let index = this.$_.findIndex(this.grid.data, {
                attendeesId: _item.userId,
              });
              // 부서 EATC00003
              if (index === -1) {
                this.grid.data.splice(0, 0, {
                  eduCourseId: this.educationInfo.eduCourseId,
                  attendeesNo: uuidv4(),
                  attendeesTypeCd: 'EATC00003',
                  eduEducationId: this.educationInfo.eduEducationId,
                  attendeesTypeName: '',
                  attendeesPositionName: _item.jobName,
                  attendanceTime: this.educationInfo.educationTime,
                  attendeesDeptName: _item.deptName,
                  seniorityName: _item.seniorName,
                  attendeesId: _item.userId,
                  enterDate: _item.enterDate,
                  attendeesName: _item.userName,
                  regUserId: this.$store.getters.user.userId,
                  chgUserId: this.$store.getters.user.userId,
                  editFlag: 'C'
                })
              }
            })
            /* eslint-disable no-unused-vars */ 
            this.$http.url = transactionConfig.sop.edu.plan.insert.targetUser.url;
            this.$http.type = 'POST';
            this.$http.param = this.grid.data;
            this.$http.request((_result) => {
              this.saveCallback1();
              this.$refs['gridUser'].selected = [];
            },);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
